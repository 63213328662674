import { BottomSheet, CbhIcon, PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Text, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { DialogFooter } from "../../components/DialogFooter";
import { TextButton } from "../../components/TextButton";
import { isShiftNameType, JobType, ShiftNameType } from "../../PlacementCandidate/types";
import { useComputedPayData } from "../lib/useComputedPay";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";
import { type PlacementData } from "../types/fetchPlacements.schema";
import { usePlacementsFilter } from "../usePlacementsFilter";
import {
  formatPayRange,
  getDefaultShiftTypeForDifferential,
  type GetDifferentialJobTraits,
  getOverallPayBreakdown,
} from "../utils";
import {
  EstimatedPayForm,
  type EstimatedPayFormData,
  estimatedPayFormSchema,
} from "./EstimatedPayForm";
import { GeneralPayInfoBottomSheet } from "./GeneralPayInfoBottomSheet";

interface EstimatedPayBottomSheetProps {
  modalState: UseModalState;
  placement: PlacementData | undefined;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function EstimatedPayBottomSheet(props: EstimatedPayBottomSheetProps) {
  const { modalState, placement } = props;

  const generalInfoModalState = useModalState();

  const payRateExperiment = usePayTransparencyFlag();

  const [placementsFilter] = usePlacementsFilter();

  const placementsFilterShiftTypes = placementsFilter?.shiftTypes
    ?.split(",")
    .filter(isShiftNameType);

  const defaultShiftType = placementsFilterShiftTypes
    ? getDefaultShiftTypeForDifferential(placementsFilterShiftTypes)
    : ShiftNameType.AM;

  const { control, watch } = useForm<EstimatedPayFormData>({
    resolver: zodResolver(estimatedPayFormSchema),
    defaultValues: {
      yearsOfExperience: undefined,
      includeBenefits: true,
      shiftType: defaultShiftType,
      isWeekendShift: placementsFilter?.jobTypes?.includes(JobType.WEEKEND_WARRIOR),
    },
  });

  const yearsOfExperience = watch("yearsOfExperience");
  const includeBenefits = watch("includeBenefits");
  const shiftType = watch("shiftType");
  const isWeekendShift = watch("isWeekendShift");

  const placements = useMemo(() => [placement].filter(isDefined), [placement]);
  const { payData, payDataMeta, isLoading } = useComputedPayData(
    placements,
    yearsOfExperience ?? undefined
  );

  const payRate = isDefined(placement) ? payData.get(placement.id) : undefined;
  const payRateMeta = isDefined(placement) ? payDataMeta.get(placement.id) : undefined;

  const differentialTraits: GetDifferentialJobTraits = {
    shiftTypes: isDefined(shiftType) ? [shiftType] : undefined,
    withBenefits: includeBenefits,
    isWeekendShift,
  };

  const payBreakdown = isDefined(payRate)
    ? getOverallPayBreakdown(payRate, differentialTraits)
    : undefined;

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_PAY_BREAKDOWN_VIEWED,
    {
      placementId: placement?.id,
      payRate:
        isDefined(payBreakdown) &&
        (payBreakdown.type === "single" ? payBreakdown.overallPay : payBreakdown.overallPayMin),
    },
    { enabled: modalState.modalIsOpen && isDefined(payBreakdown) }
  );

  if (!payRate || !payRateMeta || !payBreakdown || isLoading) {
    return null;
  }

  return payRateExperiment.enabled ? (
    <BottomSheet
      disableSwipeArea
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          backgroundColor={(theme) => theme.palette.grey[100]}
          onClose={() => {
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent
        sx={(theme) => ({
          paddingBottom: 0,
          background: `linear-gradient(180deg, ${String(theme.palette.info.light)} 20%, ${String(
            theme.palette.grey[100]
          )} 50%)`,
        })}
      >
        <Stack spacing={8}>
          <PageHeaderWrapper variant="tertiary" sx={{ background: "transparent" }}>
            <Stack direction="column" alignItems="center" spacing={10}>
              <Text variant="h6">{payRateExperiment.labelText}</Text>

              <Stack direction="column" alignItems="center" spacing={4}>
                <Text
                  sx={{
                    fontSize: payBreakdown.type === "single" ? "4rem" : "3rem",
                    fontWeight: "light",
                    textAlign: "center",
                  }}
                >
                  {formatPayRange({
                    payRate,
                    showPerHour: false,
                    differentialTraits,
                  })}
                  <Text variant="h3" display="inline">
                    /hr
                  </Text>
                </Text>
                <Text
                  variant="subtitle2"
                  color={(theme) => theme.palette.text.secondary}
                  sx={{ textAlign: "center", maxWidth: "62%" }}
                >
                  {formatPayRange({
                    payRate,
                    showPerHour: false,
                    differentialTraits: {
                      ...differentialTraits,
                      // exclude any shift differentials
                      shiftTypes: [],
                      isWeekendShift: false,
                    },
                  })}{" "}
                  base rate
                  {isDefined(payRate.benefitsDiff)
                    ? ` ${differentialTraits.withBenefits ? "with" : "without"} benefits`
                    : ""}
                  {payBreakdown.totalShiftTypeDifferential > 0
                    ? ` + ${formatCentsAsUsd(payBreakdown.totalShiftTypeDifferential)} differential`
                    : ""}
                </Text>
                <Stack component="span" display="inline-flex" direction="row" spacing={1} pt={4}>
                  <TextButton
                    size="large"
                    onClick={() => {
                      generalInfoModalState.openModal();
                    }}
                  >
                    <CbhIcon
                      type="info"
                      size="small"
                      color={(theme) => theme.palette.text.primary}
                      sx={{ verticalAlign: "middle", marginBottom: 1 }}
                    />{" "}
                    How pay rates are calculated
                  </TextButton>
                </Stack>
              </Stack>
            </Stack>
          </PageHeaderWrapper>
          <EstimatedPayForm
            control={control}
            payRate={payRate}
            payRateMeta={payRateMeta}
            totalShiftTypePayDifferential={payBreakdown.totalShiftTypeDifferential}
          />
        </Stack>
      </DialogContent>

      <GeneralPayInfoBottomSheet modalState={generalInfoModalState} />
    </BottomSheet>
  ) : null;
}
